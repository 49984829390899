import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/LayoutComponents/Layout";
import { useLocation } from "react-router-dom";
// import { Margin, usePDF } from "react-to-pdf";
import { selectSaveFileData, selectSaveFileDataError, selectSaveFileDataLoading } from "../../utils/Apis/matters/saveFileData/saveFileDataSelector";
import { saveFileData, saveFileDataReset } from "../../utils/Apis/matters/saveFileData/saveFileDataActions";
import toast from "react-hot-toast";
import GeneralModal from "../../components/Matters/Modals/GeneralModal";
import { FormInformation } from "../../utils/Apis/matters/CustomHook/PDFData";
import { PDFDocument, PDFName, rgb, StandardFonts } from 'pdf-lib';
import { pdfjs } from 'react-pdf';
import '../../components/FormPages/forms/App.css'; // Ensure this CSS file is included for styles
import 'react-resizable/css/styles.css';
import { AssetsData } from "../../utils/Apis/matters/CustomHook/DocumentViewDataUpdate";
import { AssetsDetails } from "../../utils/Apis/matters/CustomHook/AssetsData";
import { fetchFieldData } from "../../utils/Apis/matters/CustomHook/fetchFieldData";
import { selectGetFileData, selectGetFileDataLoading } from "../../utils/Apis/matters/getFileData/getFileDataSelector";
import { getFileData } from "../../utils/Apis/matters/getFileData/getFileDataActions";
import ModernToolbar from "../../components/FormPages/forms/newComponents/ModernToolbar";
import CalculationManager from "../../components/FormPages/forms/newComponents/CalculationManager";
import PDFViewer from "./PDFViewer";
import Loader from "../../components/Loader";
import CustomizationToolbar from "../../components/FormPages/forms/newComponents/CustomizationToolbar";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const FillPdf = ({ currentUserRole }) => {
  const location = useLocation();
  const formData = location.state?.formData;
  const dispatch = useDispatch();
  const [pdfUrl, setPdfUrl] = useState(null);
  console.log("🚀 ~ FillPdf ~ pdfUrl:", pdfUrl)
  const { response } = useSelector((state) => state.userProfileInfo);
  const [fields, setFields] = useState([]);
  console.log("🚀 ~ FillPdf ~ fields:", fields)
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [fieldsReady, setFieldsReady] = useState(false);
  const [scale, setScale] = useState(1.5); // Zoom level
  const [pdfDoc, setPdfDocument] = useState(null);
  const [selectedField, setSelectedField] = useState(null); // Track selected field for customization
  const [selectedFields, setSelectedFields] = useState([]);
  const [documentData, setDocumentData] = useState(null);
  const [showAddFolderModal, setShowAddFolderModal] = useState(false)
  const [blobUrl, setBlobUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); 

  let selectedForms = useSelector((state) => state.selectedForms);
  const readOnly = true;
  const { documentInfo, loading } = FormInformation(formData.matterNumber);
  console.log("🚀 ~ FillPdf ~ documentInfo:", documentInfo)
  const { selectAssetsData, selectAssetsDataLoading } = AssetsData(formData.matterNumber)
  const { debtsArray, combinedAssets, propertiesAssets } = AssetsDetails(selectAssetsData, formData.matterNumber)

  useEffect(() => {
    if (documentInfo) {
      setDocumentData(documentInfo);
    }
  }, [loading, formData, documentInfo])

  const handleEditField = (id, value) => {
    console.log("🚀 ~ handleEditField ~ value:", value)
    setFields(fields.map(field =>
      field.id === id ? { ...field, value } : field
    ));
  };

  // Modify the handleFieldSelection function
  const handleFieldSelection = (event, field) => {
    if (event.shiftKey) {
      setSelectedFields(prev => {
        const isSelected = prev.some(f => f.id === field.id);
        if (isSelected) {
          return prev.filter(f => f.id !== field.id);
        }
        return [...prev, field];
      });
    } else if (!selectedFields.some(f => f.id === field.id)) {
      setSelectedFields([field]);
    }
    setSelectedField(field);
  };

  // First, create a helper function to get the initial forms
  const getInitialForms = () => {
    if (selectedForms && selectedForms.length > 0) {
      return selectedForms;
    }
    const serializedCheckedForms = localStorage.getItem("checkedForms");
    return serializedCheckedForms ? JSON.parse(serializedCheckedForms) : [];
  };

  // Initialize the forms
  const initialForms = getInitialForms();

  // Set up state with the initial forms
  const [forms, setForms] = useState(initialForms);
  const [activeForm, setActiveForm] = useState(() => {
    // Use a state initializer function to prevent re-computation
    return initialForms.length > 0 ? initialForms[0] : null;
  });

  useEffect(() => {
    if (activeForm?.file_name) {
      setPdfUrl(`/documents/${activeForm.file_name}.pdf`);
    }
  }, [activeForm]);


  useEffect(() => {
    if (activeForm?.file_id && formData?.matterNumber) {
      let data = {
        matterId: formData.matterNumber,
        file_id: activeForm.file_id,
        folder_id: activeForm.folder_id,
      }
      dispatch(getFileData(data));
    }
  }, [activeForm, formData?.matterNumber]); // Depend on full activeForm object

  // const selectFileData = '';

  const selectFileData = useSelector(selectGetFileData);
  const selectFileDataLoading = useSelector(selectGetFileDataLoading);

  // Separate useEffect for handling selectFileData changes
  // useEffect(() => {
  //   if (selectFileData && selectFileData[0]?.file_data) {
  //     const parsedData = JSON.parse(selectFileData[0].file_data);
  //     setFields(parsedData);
  //   }
  // }, [selectFileData]);


  useEffect(() => {
    if (!pdfUrl || !documentData) {
      return;
    }
  
    const loadPdf = async () => {
      setIsLoading(true);
  
      try {
        // Load PDF document
        const response = await fetch(pdfUrl);
        const arrayBuffer = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });
        setNumPages(pdfDoc.getPageCount());
        setPdfDocument(pdfDoc);
  
        // Get static fields for the current active form
        const staticFields = await fetchFieldData(activeForm?.file_name);
  
        if (!staticFields) {
          console.error('No static fields found');
          return;
        }
        // Check selectFileData response
        if (selectFileData && selectFileData[0]?.file_data) {
          // Use saved data if it exists
          const parsedData = JSON.parse(selectFileData[0].file_data);
          setFields(parsedData);
        } else {
          // If no saved data exists, bind static fields with document data
          console.log('No saved data found, binding static fields with document data');
          

          const updatedFields = bindFieldsToData(
            staticFields,
            documentData,
            combinedAssets,
            debtsArray,
            propertiesAssets
          );
          setFields(updatedFields);
        }
      
        setFieldsReady(true);
      } catch (error) {
        console.error('Error loading the PDF:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    loadPdf();
  }, [pdfUrl, activeForm, documentData, selectFileData, selectFileDataLoading]);


    // Add this new function near your other field management functions
    const calculateAge = (dateOfBirth) => {
      if (!dateOfBirth) return '';
  
      const dob = new Date(dateOfBirth);
      const today = new Date();
      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
  
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
  
      return age;
    };
   // Bind fields to the data received from the API
  // Function to bind fields to document data and combined assets
  const bindFieldsToData = (staticFields,documentData, combinedAssets, debtsArray, propertiesAssets) => {

    const updatedFields = staticFields.map(field => {
      // Skip binding for age fields (they should keep their calculated values)
      if (field.isAgeField) {
        return field;
      }

      // Initialize the binding value with current field value
      let bindingValue = field.value;

      // Check if the bind property is defined
      if (field.bind) {
        // Split the bind string into an array of keys
        const keys = field.bind.split(',').map(key => key.trim());

        // Retrieve values from the document data
        const valuesFromDocument = keys.map(key => {
          return key.split('.').reduce((acc, curr) => acc && acc[curr], documentData) || ''; // Safely traverse the data object
        });

        // If there's only one binding, assign that value; if multiple, join the values
        bindingValue = valuesFromDocument.length > 1 ? valuesFromDocument.join(', ') : valuesFromDocument[0];

        // If this is a date field with a linked age field, recalculate the age
        if (field.type === 'Date' && field.linkedAgeField) {
          const age = calculateAge(bindingValue);
          // Find and update the linked age field
          const linkedField = fields.find(f => f.id === field.linkedAgeField);
          if (linkedField) {
            linkedField.value = age.toString();
          }
        }
      }

      if (field.source === 'assets') {
        const assetIndex = field.bind ? field.bind.match(/\[(\d+)\]/) : null;
        const bindRoot = field.bind ? field.bind.split('.')[0] : null;

        if (field.isCombined && field.sourceFields) {
          // Handle combined fields
          const bindInfo = field.bind ? field.bind.split('.')[2] : null;
          const combinedValue = field.sourceFields.map(sourceField => {
            const fieldIndex = sourceField.match(/\[(\d+)\]/)?.[1];
            const fieldRoot = sourceField.split('.')[0];

            if (fieldIndex && fieldRoot) {

              const sourceArray = documentData?.assets?.[fieldRoot];
              console.log("🚀 ~ combinedValue ~ sourceArray:", sourceArray)
              if (sourceArray && sourceArray[fieldIndex]) {
                const fieldKey = sourceField.split('.')[2];
                const sourceItem = sourceArray[fieldIndex];
                console.log("🚀 ~ combinedValue ~ sourceItem:", fieldKey)
                const value = sourceItem[fieldKey] || '';
                // Return the value from the specified field
                return value;
              }
            }
            return '';
          }).filter(value => value).join(', '); // Join non-empty values with comma and space



          console.log("🚀 ~ combinedValue ~ combinedValue:", combinedValue)
          if (field.bind.includes(bindInfo)) {
            bindingValue = combinedValue || '';
          }
          // bindingValue = combinedValue || bindingValue;
        } else if (assetIndex && bindRoot) {
          // Handle single field binding
          const index = parseInt(assetIndex[1], 10);
          const assetsArray = documentData?.assets?.[bindRoot];
          if (assetsArray && assetsArray[index]) {
            const assetsItem = assetsArray[index];
            if (field.bind.includes('today')) {
              bindingValue = assetsItem.today || bindingValue;
            }
          }
        }
      }

      if (field.source === 'debts') {
        // Check for combined assets binding only if field.bind is defined
        const debtsIndex = field.bind ? field.bind.match(/items\[(\d+)\]/) : null;
        if (debtsIndex) {
          const index = debtsIndex[1]; // Extract index
          const debtsItem = debtsArray?.items[index];
          if (debtsItem) {

            if (field.bind.includes('category')) {
              bindingValue = debtsItem.category || ''; // Override with asset value if available
            }
            if (field.bind.includes('details')) {
              bindingValue = debtsItem.details || ''; // Override with asset value if available
            }
            if (field.bind.includes('on_date_of_marriage')) {
              bindingValue = debtsItem.on_date_of_marriage || ''; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = debtsItem.today || ''; // Override with asset value if available
            }

            if (field.bind.includes('on_valuation_date')) {
              bindingValue = debtsItem.on_valuation_date || ''; // Override with asset value if available
            }

            if (field.bind.includes('client')) {
              bindingValue = ''; // Override with asset value if available
            }
          }
        }
      }

      if (field.source === 'debtsMarriage') {
        // Check for combined assets binding only if field.bind is defined
        const debtsIndex = field.bind ? field.bind.match(/items\[(\d+)\]/) : null;
        if (debtsIndex) {
          const index = debtsIndex[1]; // Extract index
          const debtsItem = debtsArray?.items[index];
          if (debtsItem) {

            if (field.bind.includes('category')) {
              bindingValue = debtsItem.category || ''; // Override with asset value if available
            }

            if (field.bind.includes('on_valuation_date')) {
              bindingValue = debtsItem.on_valuation_date || ''; // Override with asset value if available
            }

            if (field.bind.includes('client')) {
              bindingValue = ''; // Override with asset value if available
            }
          }
        }
      }

      if (field.source === 'properties') {
        // Check for combined assets binding only if field.bind is defined
        const propertiesIndex = field.bind ? field.bind.match(/items\[(\d+)\]/) : null;
        if (propertiesIndex) {
          const index = propertiesIndex[1]; // Extract index
          const propertiesItem = propertiesAssets?.items[index];
          if (propertiesItem) {
            if (field.bind.includes('item')) {
              bindingValue = propertiesItem.item || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('market_value.client.today')) {
              bindingValue = propertiesItem.market_value.client.on_date_of_marriage || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('market_value.opposing_party.today')) {
              bindingValue = propertiesItem.market_value.opposing_party.on_date_of_marriage || ''; // Override with asset value if available
            }
          }
        }
      }

      // New logic for excluded properties
      if (field.source === 'property_excluded') {
        const excludedIndex = field.bind ? field.bind.match(/items\[(\d+)\]/) : null;
        if (excludedIndex) {
          const index = excludedIndex[1]; // Extract index
          const excludedItem = propertiesAssets?.items[index]; // Assume you are using propertiesAssets for excluded items
          if (excludedItem) {
            if (field.bind.includes('item')) {
              bindingValue = excludedItem.item || bindingValue; // Override with excluded asset value if available
            }
            if (field.bind.includes('market_value.client.on_valuation_date')) {
              bindingValue = excludedItem.market_value.client.on_valuation_date || bindingValue; // Override with excluded asset value if available
            }
            if (field.bind.includes('market_value.opposing_party.on_valuation_date')) {
              bindingValue = excludedItem.market_value.opposing_party.on_valuation_date || bindingValue; // Override with excluded asset value if available
            }
            if (field.bind.includes('market_value.client.today')) {
              bindingValue = excludedItem.market_value.client.today || bindingValue; // Override with excluded asset value if available
            }
            if (field.bind.includes('market_value.opposing_party.today')) {
              bindingValue = excludedItem.market_value.opposing_party.today || bindingValue; // Override with excluded asset value if available
            }
          }
        }
      }

      if (field.source === 'houseHold') {
        const houseHoldIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (houseHoldIndex) {
          const index = parseInt(houseHoldIndex[1], 10); // Extract index
          const houseHoldItem = documentData?.assets?.household?.[index];
          if (houseHoldItem) {
            if (field.bind.includes('description')) {
              bindingValue = houseHoldItem.description || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = houseHoldItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No household item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'realEstate') {
        const realEstateIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (realEstateIndex) {
          const index = parseInt(realEstateIndex[1], 10); // Extract index
          const realEstateItem = documentData?.assets?.land?.[index];

          if (realEstateItem) {
            if (field.bind.includes('address')) {
              bindingValue = + realEstateItem.ownership + ', ' + realEstateItem.address || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = realEstateItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No real estate item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'lifeInsurance') {
        const lifeInsuranceIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (lifeInsuranceIndex) {
          const index = parseInt(lifeInsuranceIndex[1], 10); // Extract index
          const lifeInsuranceItem = documentData?.assets?.life?.[index];

          if (lifeInsuranceItem) {
            if (field.bind.includes('category')) {
              bindingValue = lifeInsuranceItem.beneficiary || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = lifeInsuranceItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No life insurance item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'bank') {
        const bankIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (bankIndex) {
          const index = parseInt(bankIndex[1], 10); // Extract index
          const bankItem = documentData?.assets?.bank?.[index];


          if (bankItem) {
            if (field.bind.includes('category')) {
              bindingValue = bankItem.institution || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = bankItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No bank item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'interests') {
        const interestsIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (interestsIndex) {
          const index = parseInt(interestsIndex[1], 10); // Extract index
          const interestsItem = documentData?.assets?.interests?.[index];

          if (interestsItem) {
            if (field.bind.includes('firm_name')) {
              bindingValue = interestsItem.firm_name || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = interestsItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No interests item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'moneyOwed') {
        const moneyOwedIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (moneyOwedIndex) {
          const index = parseInt(moneyOwedIndex[1], 10); // Extract index
          const moneyOwedItem = documentData?.assets?.moneyOwed?.[index];
          if (moneyOwedItem) {
            if (field.bind.includes('category')) {
              bindingValue = moneyOwedItem.details || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = moneyOwedItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No money owed item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'otherPossessions') {
        const otherPossessionsIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (otherPossessionsIndex) {
          const index = parseInt(otherPossessionsIndex[1], 10); // Extract index
          const otherPossessionsItem = documentData?.assets?.otherpossessions?.[index];

          if (otherPossessionsItem) {
            if (field.bind.includes('address')) {
              bindingValue = otherPossessionsItem.address || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = otherPossessionsItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No other possessions item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'investments') {
        const investmentsIndex = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null;
        if (investmentsIndex) {
          const index = parseInt(investmentsIndex[1], 10); // Extract index
          const investmentsItem = documentData?.assets?.investments?.[index];

          if (investmentsItem) {
            if (field.bind.includes('type')) {
              bindingValue = investmentsItem.type || ''; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = investmentsItem.today || ''; // Override with asset value if available
            }
          } else {
            console.warn(`No investments item found at index: ${index}`); // Log if item not found
          }
        }
      }

      if (field.source === 'savings') {
        // Check for combined assets binding only if field.bind is defined
        const savingsIndexMatch = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null; // Changed variable name to avoid confusion
        if (savingsIndexMatch) {
          const index = parseInt(savingsIndexMatch[1], 10); // Extract index and convert to an integer
          const savingsItem = documentData?.assets?.savings ? documentData.assets.savings[index] : undefined;

          // Check if savingsItem exists and handle the binding
          if (savingsItem) {
            if (field.bind.includes('description')) {
              bindingValue = savingsItem.description || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = savingsItem.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No savings asset found at index: ${index}. Keeping previous binding value: ${bindingValue}`); // Log a warning if the asset does not exist
          }
        } else {
          console.warn(`Binding format not recognized: ${field.bind}`); // Log a warning if the binding format is incorrect
        }
      }

      if (field.source === 'Other') {
        // Check for combined assets binding only if field.bind is defined
        const otherIndexMatch = field.bind ? field.bind.match(/assets\[(\d+)\]/) : null; // Changed variable name to avoid shadowing
        if (otherIndexMatch) {
          const index = parseInt(otherIndexMatch[1], 10); // Extract index and convert it to an integer
          const otherAsset = documentData?.assets?.other ? documentData.assets.other[index] : undefined;

          // Check if otherAsset exists and handle the binding
          if (otherAsset) {
            if (field.bind.includes('description')) {
              bindingValue = otherAsset.description || bindingValue; // Override with asset value if available
            }
            if (field.bind.includes('today')) {
              bindingValue = otherAsset.today || bindingValue; // Override with asset value if available
            }
          } else {
            console.warn(`No asset found at index: ${index}. Keeping previous binding value: ${bindingValue}`); // Log a warning if the asset does not exist
          }
        } else {
          console.warn(`Binding format not recognized: ${field.bind}`); // Log a warning if the binding format is incorrect
        }
      }

      if (field.source === 'debts') {
        const bindMatch = field.bind ? field.bind.match(/(\w+)\[(\d+)\]\.(\w+)/) : null;

        if (bindMatch) {
          const debtType = bindMatch[1]; // Extract the debt type (e.g., mortgages, lineofcredits)
          const index = parseInt(bindMatch[2], 10); // Extract the index from the binding
          const fieldName = bindMatch[3]; // Extract the specific field name (e.g., monthlyPayment)

          const debtItem = documentData?.debts?.[debtType]?.[index];

          if (debtItem) {
            // Bind the appropriate field value based on its bind property
            bindingValue = debtItem[fieldName] || bindingValue; // Override with debt item value if available
          } else {
            console.warn(`No debt item found at index: ${index} for debt type: ${debtType}`); // Log if item not found
          }
        }
      }

      if (field.source?.startsWith('specialExpenses')) {
        const bindMatch = field.bind ? field.bind.match(/specialExpenses\.(\w+)\[(\d+)\]\.(\w+)/) : null;

        if (bindMatch) {
          const entity = bindMatch[1]; // Extract the entity (client or opposingParty)
          const index = parseInt(bindMatch[2], 10); // Extract the index
          const fieldName = bindMatch[3]; // Extract the specific field name (e.g., amount, tax)

          const specialExpenseItem = documentData?.specialExpenses?.[entity]?.[index];

          if (specialExpenseItem) {
            // Bind the appropriate field value based on its bind property
            bindingValue = specialExpenseItem[fieldName] || bindingValue; // Override with special expense item value if available
          } else {
            console.warn(`No special expense item found at index: ${index} for entity: ${entity}`); // Log if item not found
          }
        }
      }

      if (field.source === 'assets.property') {
        // Extract the specific property, index, and field name from the bind string
        const bindMatch = field.bind ? field.bind.match(/assets\.property\.(\w+)\.(\w+)/) : null;

        if (bindMatch) {
          const propertyType = bindMatch[1]; // Extract the property type (e.g., land, household, bank)
          const fieldName = bindMatch[2]; // Extract the specific field name (e.g., assets, liabilities)

          // Retrieve the specific property data from documentData
          const propertyData = documentData?.assets?.property?.[propertyType];

          if (propertyData && propertyData[fieldName] !== undefined) {
            // Override the field value with the data from documentData if available
            field.value = propertyData[fieldName];
          } else {
            console.warn(`No data found for property: ${propertyType}, field: ${fieldName}`); // Log if item not found
          }
        }
      }

      // Handling binding for 'assets.tables'
      if (field.source === 'assets.table') {
       
          // If no direct match, try the regex pattern
         const bindMatch = field.bind ? field.bind.match(/assets\.(\w+)\[(\d+)\]\.(\w+)/) : null;
        
        if (bindMatch) {
          const category = bindMatch[1]; // Extract the category (e.g., land, bank)
          const index = parseInt(bindMatch[2], 10); // Extract the index (e.g., 0, 1, 2)
          const fieldName = bindMatch[3]; // Extract the field name (e.g., today, onValuationDate)

          // Check that the sourceType matches the category in the bind string
          if (field.sourceType === category) {
            const assetItem = documentData?.assets?.[category]?.[index];
            if (assetItem) {
              // If data exists, update the field's value based on the document data
              bindingValue = assetItem[fieldName] || bindingValue;

            } else {
              console.warn(`No asset item found at index: ${index} for category: ${category}`);
            }
          } else {
            console.warn(`Field sourceType '${field.sourceType}' does not match category '${category}' in binding.`);
          }
        }
      }

      if (field.source === 'children') {
        // Check if the field has a bind property and extract the relevant information
        const bindMatch = field.bind ? field.bind.match(/theChildren\[(\d+)\]\.(\w+)/) : null;

        if (bindMatch) {
          const index = parseInt(bindMatch[1], 10); // Extract the index from the binding, e.g., 0
          const fieldName = bindMatch[2]; // Extract the specific field name, e.g., fullLegalName

          const childItem = documentData?.theChildren?.[index]; // Get the corresponding child item

          if (childItem) {
            // Bind the appropriate field value based on its bind property
            bindingValue = childItem[fieldName] || bindingValue; // Assign the value from the data or set to empty if not available
          } else {
            console.warn(`No child item found at index: ${index} for field: ${fieldName}`); // Log a warning if the item is not found
          }
        } else {
          console.error(`Failed to parse bind value: ${field.bind} for source: children`); // Log an error if the binding pattern doesn't match
        }
      }

      return { ...field, value: bindingValue }; // Return the updated field with the bound value
    });
    return updatedFields;
  };
   


  const selectSaveData = useSelector(selectSaveFileData);
  const selectSaveDataLoading = useSelector(selectSaveFileDataLoading);
  const selectSaveDataError = useSelector(selectSaveFileDataError);

  const handleSave = () => {
    let data = {
      matterId: formData.matterNumber,
      active_form: activeForm.id,
      file_id: activeForm.file_id,
      folder_id: activeForm.folder_id,
      doc_id: activeForm.docId,
      data: fields
    }

    dispatch(saveFileData(data))
  }

  useEffect(() => {
    if (selectSaveData) {
      toast.success("Data Successfully Saved",
        {
          position: "top-right",
          style: {
            borderRadius: '10px',
            background: '#FFF',
            color: '#000',
          },
        })
      dispatch(saveFileDataReset())
    }
  }, [selectSaveDataLoading, selectSaveData])

  const savePdf = async () => {
    if (!fields || !Array.isArray(fields)) {
      console.error("Fields is null or not an array.");
      return;
    }

    const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
    const pages = pdfDoc.getPages();
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const form = pdfDoc.getForm();

    if (fields.length > 0) {
      fields.forEach(field => {
        const pageIndex = field.page - 1;
        if (pageIndex < 0 || pageIndex >= pages.length) {
          console.error(`Page index ${pageIndex + 1} is out of bounds.`);
          return;
        }

        const page = pages[pageIndex];

        const fieldProperties = {
          x: parseFloat(field.x) || 0,
          y: page.getHeight() - parseFloat(field.y) - (parseFloat(field.height) / scale),
          width: parseFloat(field.width) / scale,
          height: parseFloat(field.height) / scale,
          textColor: rgb(0, 0, 0),
          borderWidth: 0
        };

        if (field.type === 'TextField' || field.type === 'Number' || field.type === 'TextArea') {
          const textField = form.createTextField(field.id.toString());
          textField.addToPage(page, fieldProperties);

          const widgets = textField.acroField.getWidgets();
          widgets.forEach(widget => {
            const dict = widget.dict;
            dict.delete(PDFName.of('BS'));
            dict.delete(PDFName.of('MK'));
            dict.delete(PDFName.of('BG'));
          });

          if (field.type === 'TextArea') {
            textField.enableMultiline();
          }

          const value = field.value != null ? String(field.value) : '';
          textField.setText(value);
          textField.setFontSize(8);

          if (readOnly) {
            textField.enableReadOnly();
          }
        }
        else if (field.type === 'CheckBox') {
          const checkbox = form.createCheckBox(field.id.toString());

          // Add checkbox with minimal properties
          checkbox.addToPage(page, {
            x: parseFloat(field.x) || 0,
            y: page.getHeight() - parseFloat(field.y) - (15 / scale),
            width: 10,
            height: 10,
            borderWidth: 0,
            borderColor: rgb(0, 0, 0, 0)  // Fully transparent border color
          });

          // Set appearance characteristics for the checkbox
          const widgets = checkbox.acroField.getWidgets();
          widgets.forEach(widget => {
            const dict = widget.dict;

            // Remove border and background settings
            dict.delete(PDFName.of('BS'));
            dict.delete(PDFName.of('MK'));
            dict.delete(PDFName.of('BG'));

            // Set default appearance for text color (for the checkmark)
            dict.set(PDFName.of('DA'), PDFName.of('/ZaDb 0 Tf 0 g'));
          });

          if (readOnly) {
            checkbox.enableReadOnly();
          }

          // Set the checkbox state
          if (field.value === 'checked') {
            checkbox.check();
          } else {
            checkbox.uncheck();
          }
        }
      });
    } else {
      console.error("No fields available to save.");
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfBlobUrl = URL.createObjectURL(blob);
    setShowAddFolderModal(true);
    setBlobUrl(pdfBlobUrl);
  };

  const updateFields = useCallback((newFields) => {
    setFields(newFields);
  }, []); // Empty dependency array since setFields is stable


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Layout title={`Welcome ${response.first_name} ${response.last_name}`}>
      <div className="fill-information-page panel trans">
        <div className="pBody">
          <div className="row">
          <div className={isSidebarOpen ? "col-md-9" : "col-md-12"}>
              <div className="page-content">
                <div className="head d-flex justify-content-between align-items-center">
                  {activeForm?.title || 'No Title'}
                  <div style={{ alignItems: 'right', display: 'flex', gap: '10px' }}>
                    <button className='btn btnPrimary' onClick={handleSave}>
                      Save Document
                    </button>

                    <button className='btn btnPrimary' onClick={savePdf}>
                      Download Document
                    </button>
                    <button className='btn btnPrimary' onClick={toggleSidebar}>
                    {isSidebarOpen ? 'Hide Forms List' : 'Show Forms List'}
                  </button>
                  </div>
                </div>
                <div className="body"  style={{ backgroundColor: '#e3e3e3' }}>
                  <div id="pdf-content">
                    {isLoading ? (
                      <Loader isLoading={isLoading} />
                    ) : pdfUrl && (
                      <> 
                      <div className="toolbar">
                      <ModernToolbar
                        currentPage={currentPage}
                        numPages={numPages}
                        setCurrentPage={setCurrentPage}
                        setScale={setScale}
                        scale={scale}
                      />
                    </div>
                      <div id='pdfContainer' style={{
                          flex: 1,
                          position: 'relative',
                        }}>
                        <PDFViewer
                          pdfUrl={pdfUrl}
                          currentPage={currentPage}
                          scale={scale}
                          fields={fields}
                          handleFieldSelection={handleFieldSelection}
                          selectedFields={selectedFields}
                          handleEditField={handleEditField}  // Important for editing
                          setFields={setFields}  // Important for checkbox updates
                        />
                      </div> 
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-md-3 sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
              <div className="page-sidebar">
                <div className="head">List of Forms</div>
                <div className="body">
                  <div className="content">
                    <CalculationManager
                      fields={fields}
                      setFields={updateFields}  // Pass the memoized callback
                      selectedFields={selectedFields}
                      currentPage={currentPage}
                    />

                    {forms.map((form, index) => (
                      <div
                        className="form-checkbox"
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          const newForms = [...forms];
                          newForms[index].checked = !newForms[index].checked;
                          setForms(newForms);
                          setActiveForm(newForms[index]);
                          setNumPages(null);
                          setCurrentPage(1)
                          setFields([])
                          setFieldsReady(false);
                        }}
                      >
                        <button className={`btn btnPrimary mb-2 w-100 ${activeForm.shortTitle === form.shortTitle ? 'button-active' : ''}`}>{form.shortTitle}</button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GeneralModal
        show={showAddFolderModal}
        changeShow={() => setShowAddFolderModal(false)}
        handleClick={() => setShowAddFolderModal(false)}
        heading={`Preview PDF: ${activeForm.title}`}
        size='sm'
        dialogClassName={'matterModal'}
      >
        {pdfUrl && (
          <iframe src={blobUrl} style={{ width: '100%', height: '500px' }} title="PDF Preview"></iframe>
        )}
      </GeneralModal>
    </Layout>
  );
};

export default FillPdf;
