import React from 'react';
import { Document, Page } from 'react-pdf';
import { Rnd } from 'react-rnd';
import CurrencyFormat from 'react-currency-format';

const PDFViewer = ({
  pdfUrl,
  onLoadSuccess,
  currentPage,
  scale,
  handleDrop,
  fields,
  handleFieldSelection,
  selectedFields,
  handleEditField,
  handleSort,
  handleCellEdit,
  formatDate,
  setFields
}) => {
  if (!fields) return null;

  return (
    <Document file={pdfUrl} onLoadSuccess={onLoadSuccess}>
      <Page
        className="pdf-canvas"
        pageNumber={currentPage}
        scale={scale}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      >
        {fields.map(field => (
          field.page === currentPage && (
            <Rnd
              key={field.id}
              size={{ width: field.width, height: field.height }}
              position={{ x: field.x * scale, y: field.y * scale }}
              bounds="parent"
              style={{
                border: field.border,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'white',
              }}
              onClick={(event) => handleFieldSelection(event, field)}
            >
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  fontSize: field.fontSize,
                  padding: '0',
                  backgroundColor: selectedFields.some(selected => selected.id === field.id) ? 'rgba(0, 123, 255, 0.5)' : 'transparent',
                }}
              >
                {/* Field Type Rendering */}
                {renderField(field, handleEditField, handleSort, handleCellEdit, formatDate, setFields)}
              </div>
            </Rnd>
          )
        ))}
      </Page>
    </Document>
  );
};

// Helper function to render different field types
const renderField = (field, handleEditField, handleSort, handleCellEdit, formatDate, setFields) => {
  switch (field.type) {
    case 'TextField':
      return (
        <input
          id={field.id}
          type="text"
          value={field.value}
          onChange={(e) => handleEditField(field.id, e.target.value)}
          style={getFieldStyle(field)}
        />
      );
    case 'TextArea':
      return (
        <textarea
          id={field.id}
          value={field.value}
          onChange={(e) => handleEditField(field.id, e.target.value)}
          style={getFieldStyle(field)}
        />
      );
    case 'Table':
      return renderTable(field, handleSort, handleCellEdit);
    case 'Number':
      return (
        <CurrencyFormat
          style={getFieldStyle(field)}
          id={field.id}
          value={field.value}
          thousandSeparator={true}
          prefix={''}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
          }}
          onChange={(e) => handleEditField(field.id, e.target.value)}
        />
      );
    case 'Date':
      return (
        <input
          id={field.id}
          type="date"
          value={field.value}
          onChange={(e) => {
            const date = new Date(e.target.value);
            const formattedDate = formatDate(date, field.dateFormat);
            handleEditField(field.id, formattedDate);
          }}
          style={getFieldStyle(field)}
        />
      );
    default:
      return (
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <input
          id={field.id}
            type="checkbox"
            checked={field.value === 'checked'}
            onChange={() => {
              setFields((prevFields) =>
                prevFields.map((f) =>
                  f.id === field.id ? { ...f, value: f.value === 'checked' ? 'unchecked' : 'checked' } : f
                )
              );
            }}
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              marginRight: '5px'
            }}
          />
        </label>
      );
  }
};

// Helper function to render table fields
const renderTable = (field, handleSort, handleCellEdit) => (
  <div
    id={`table-${field.id}`}
    style={{
      width: '100%',
      height: '100%',
      overflow: 'auto'
    }}
  >
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      fontSize: field.fontSize
    }}>
      <thead>
        <tr>
          {field.data[0].map((header, index) => (
            <th key={index}>
              {header}
              {field.sortable && (
                <button
                  onClick={() => handleSort(field.id, index)}
                  style={{ marginLeft: 4 }}
                >
                  ↕️
                </button>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {field.data.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>
                {field.isEditable ? (
                  <input
                    type="text"
                    value={cell}
                    onChange={(e) => handleCellEdit(field.id, rowIndex, cellIndex, e.target.value)}
                    style={{ width: '100%', border: 'none', background: 'transparent' }}
                  />
                ) : (
                  cell
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

// Helper function to get common field styles
const getFieldStyle = (field) => ({
  fontSize: field.fontSize,
  color: `rgb(${field.color[0]}, ${field.color[1]}, ${field.color[2]})`,
  width: '100%',
  border: `1px solid rgb(255, 144, 0, 0.5)`,
  background: field.background,
  height: '100%',
  boxSizing: 'border-box',
  backgroundColor: `rgb(255, 144, 0, 0.2)`,
});

export default PDFViewer;
