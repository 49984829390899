import { useEffect, useState, useRef } from "react";
import Layout from "../LayoutComponents/Layout";
import {
  getCurrentUserFromCookies,
  getUserSID,
  getUserId,
  getShortFirmname,
} from "../../utils/helpers";
import { useHistory } from "react-router";
import axios from "../../utils/axios";
import Loader from "../Loader";
import { getSvg } from "../../pages/complianceForms/Compliance_assets";
import { useReactToPrint } from 'react-to-print';
import TrustDepositSlipCashTable from "./TrustDepositSlipCashTable";
import { removeNegSignAndWrapInBrackets, wrapInBracketsIfNeg } from "../../pages/calculator/reports";
import toast from "react-hot-toast";

const FormTrustDepositSlip = () => {
  const history = useHistory();

  const [bankAccountDetails, setBankAccountDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [clientFilesDetails, setClientFilesDetails] = useState([]);
  const [clientFilesDetailsCheque, setClientFilesDetailsCheque] = useState([]);


  console.log('clientFileDeay', clientFilesDetails)

  const formTarget = useRef();
  const [valueErrorMismatch, setValueErrorMismatch] = useState({
  })
  const [amountDifference, setAmountDifference] = useState({})


  useEffect(() => {

    console.log('gsdfhkEffect', JSON.parse(history.location.state.client_files_details))

    let chequeTransactions = history.location.state.client_files_details && JSON.parse(history.location.state.client_files_details).filter(ele =>
      ele.value.transaction_type.toUpperCase().includes('CHECK') ||
      ele.value.transaction_type.toUpperCase().includes('CHEQUE') ||
      ele.value.confirmation.toUpperCase().includes('CHEQUE') ||
      ele.value.transaction_type.toUpperCase().includes('CHECK')
    );

    const cashTransactions = history.location.state.client_files_details && JSON.parse(history.location.state.client_files_details).filter(ele =>
      ele.value.confirmation.toUpperCase().includes('CASH') ||
      ele.value.transaction_type.toUpperCase().includes('CASH')
    );

    setClientFilesDetailsCheque(chequeTransactions)
    setClientFilesDetails(cashTransactions)
    setSelectedDate(history.location.state.task_due_date)
  }, [history])


  const { task_type_account, account_id, client_files_details, id } = history.location.state;

  useEffect(() => {
    setLoader(true)
    axios.get(`/clio-account-details/${account_id}/${getUserSID()}`).then((res) => {
      setBankAccountDetails(res.data.data.body[0])
      setLoader(false)
    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })
  }, [account_id]);

  const ButtonWithLoader = ({ isLoading, onClick, text, disabled }) => (
    <button className="btn btnPrimary btn-sm mb-2" disabled={disabled} onClick={onClick}>
      {isLoading ?
        <>
          <i class="fa fa-refresh fa-spin " style={{ marginRight: '5px' }}></i>
          Loading...
        </>
        : text
      }
    </button>
  );

  const handlePrint = useReactToPrint({
    content: () => formTarget.current,
    documentTitle: 'Trust Deposit Slip',

    pageStyle: `
      @page {
        size: A4;
        margin: 0;
      }
      body {
        margin: 0;
      }
    `,
  });

  const handleDownloadClick = () => {
    handlePrint();
  }

  const handleSaveClick = (key) => {

    let updatedFileDetails = [...clientFilesDetailsCheque, ...clientFilesDetails]

    let updateData = {
      sid: getUserSID(),
      uid: getUserId(),
      task_id: id,
      clientFilesDetails: updatedFileDetails,
      task_due_date: selectedDate
    }

    let data = {
      sid: getUserSID(),
      uid: getUserId(),
      content: formTarget.current.innerHTML,
      task_id: id,
      pdfname: 'Trust Deposit Slip',
      bucket: `cloudact-${getShortFirmname().toLowerCase()}`
    }
    
    Promise.all([
      axios.post('/save_trust_deposit_Slip', data),
      axios.put('/update/task/trust_deposit_slip', updateData)
    ]).then(responses => {
      toast.success('Saved successfully ');
      console.log('PDF created successfully:', responses[0]);
    }).catch(error => {
      toast.error('Something went wrong');
      console.error('Error in one of the requests:', error);
    });
  }

  const coinHandler = (event, index, totalAmount, nextInputReadValue, denomination) => {
    let { name, value } = event.target;

    let calculatedValue;
    if (denomination == 0) {
      calculatedValue = Number(value)
    } else {
      calculatedValue = value * denomination;
    }

    let newData = [...clientFilesDetails];
    let currentCoinData = newData[index].value.coin || {};
    let currentBillData = newData[index].value.bill || {};

    newData[index].value.bill = { ...currentBillData, [name]: value }
    newData[index].value.coin = { ...currentCoinData, [nextInputReadValue]: calculatedValue }

    let totalAmountWithSpecificIndex =
      (isNaN(newData[index].value.coin['5billamount']) ? 0 : newData[index].value.coin['5billamount']) +
      (isNaN(newData[index].value.coin['10billamount']) ? 0 : newData[index].value.coin['10billamount']) +
      (isNaN(newData[index].value.coin['20billamount']) ? 0 : newData[index].value.coin['20billamount']) +
      (isNaN(newData[index].value.coin['50billamount']) ? 0 : newData[index].value.coin['50billamount']) +
      (isNaN(newData[index].value.coin['100billamount']) ? 0 : newData[index].value.coin['100billamount']) +
      (isNaN(newData[index].value.coin['totalcoinamount']) ? 0 : newData[index].value.coin['totalcoinamount'])

    const key = `${index}-${name}`;
    newData[index].value.coin['totalamount'] = totalAmountWithSpecificIndex

    if (newData[index].value.coin['totalamount'] > totalAmount) {
      setValueErrorMismatch({
        ...valueErrorMismatch,
        [key]: true,
      });

      setAmountDifference({
        ...amountDifference,
        [key]: value - totalAmount
      })
    } else {

      setValueErrorMismatch({
        ...valueErrorMismatch,
        [key]: false,
      });
      setAmountDifference({
        ...amountDifference,
        [key]: 0
      })
    }
    setClientFilesDetails(newData);
  }





  return (
    <Layout title={'Welcome'}>
      <Loader isLoading={loader} loadingMsg="Loading..." />
      <div className="trustDepositOuterWrap">
     

        <div className="row">
          <div className="col-md-9" ref={formTarget}>
            {StyleSheet()}
          

            <div className="trustDepositWrapper">
          
              <section>
                <span style={{fontWeight:'bold'}}>DEPOSIT SLIP</span>
            
                <span style={{marginBottom:'8px'}}  >Current Account  : <span style={{marginLeft:'5px'}}>{task_type_account}</span></span>
               
                <span style={{marginBottom:'8px'}}  >Date:<input type="date" style={{marginLeft:'5px'}} value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value) }} /></span>
            <span style={{marginBottom:'8px'}}  >Transit: <span style={{marginLeft:'5px'}} >{bankAccountDetails?.transit_number} </span> <text>Teller Stamp</text></span>
               <span style={{marginBottom:'8px'}}  >Account Number:  <span style={{marginLeft:'5px' }} > {bankAccountDetails?.account_number}</span></span>
                {
                  clientFilesDetailsCheque && clientFilesDetailsCheque.length !== 0 && <table>
                    <thead>
                      <tr>
                        <th>Client</th>
                        <th>Matter</th>
                        <th>Recieved from</th>
                        <th>Cheques and Credit Vouchers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        clientFilesDetailsCheque && clientFilesDetailsCheque.map((ele, index) => {
                          return <>
                            <tr key={index}>
                              <td>{ele.value.client_name}</td>
                              <td>{ele.value.matter_display_number}</td>
                              <td>{ele.value.other_party}</td>
                              <td>{removeNegSignAndWrapInBrackets(ele.value.amount)}</td>
                            </tr>
                          </>
                        })
                      }
                      <tr className="total">
                        <td style={{ textAlign: 'left' }} colSpan={3}>Total</td>
                        <td>{clientFilesDetailsCheque && removeNegSignAndWrapInBrackets(clientFilesDetailsCheque.reduce((acc, curr) => acc + curr.value.amount, 0))}</td>
                      </tr>
                    </tbody>
                  </table>
                }
                <TrustDepositSlipCashTable
                  setValueErrorMismatch={setValueErrorMismatch}
                  amountDifference={amountDifference}
                  valueErrorMismatch={valueErrorMismatch}
                  coinHandler={coinHandler}
                  clientFilesDetails={clientFilesDetails}
                />
                <table >
                  <tbody>
                  <tr className="total">
                    <td colSpan={5} style={{ textAlign: 'left' }}>Total</td>
                    <td style={{ textAlign: 'right' }}>
                      {history.location.state.client_files_details && 
                      removeNegSignAndWrapInBrackets(
                        JSON.parse(history.location.state.client_files_details).reduce((acc, curr) => acc + curr.value.amount, 0))}
                        
                        </td>
                  </tr>
                  </tbody>
                </table>


              </section>
              <aside>
                <span>Credit amount of <br />CloutAct Demo <br />Trust Account</span>
                <span>Depositor's <br />Initials</span>
                <span>Teller's <br />Initials</span>
              </aside>
            </div>
          </div>

          <div className="col-md-3 pt-5">
            <div className="pt-5 mt-5 position-sticky" style={{ top: '210px' }}>
              <h5 className="mb-3">
                {getSvg('Compliance form')}
                Trust Deposit Slip
              </h5>

              Сheck the information in the document <br />
              and then you can save it or print it:

              <div className="d-flex mt-3" style={{ gap: '10px' }}>
                <ButtonWithLoader
                  // isLoading={loadingApprover.saveForm}
                  onClick={handleSaveClick}
                  text='Save Document'
                />

                <button className="btn btnPrimary btn-sm mb-2"
                  onClick={handleDownloadClick}
                >
                  Print/Download
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

    </Layout>
  );
};

export default FormTrustDepositSlip;



const StyleSheet = () => {
  return (  <style>
    {`
      .trustDepositWrapper {
        display: flex;
      }
      @media print {
        .trustDepositWrapper {
          padding: 40px;
          width: 100%;
        }
      }
      .trustDepositWrapper > section{
        padding-right: 20px;
        display: flex;
        gap:10px;
        flex-direction: column;
        width: 100%;
      }
      .trustDepositWrapper > section > span{
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .trustDepositWrapper input{
        border:#999 solid 1px;
       
      }

      .trustDepositWrapper table td input {
       max-width: 100px;
      }

      .trustDepositWrapper > section > span text{
        margin-left: auto;
      }
      .trustDepositWrapper > section > *{
        width: 100%;
      }
      .trustDepositWrapper table:not(.noStyle) td, .trustDepositWrapper table:not(.noStyle) th{
        border:#ddd solid 1px;
        text-align: center;
        padding:10px;
        vertical-align: baseline;
      }
      .trustDepositWrapper aside {
        min-width: 180px; 
        max-width: 180px;
        border: #ddd solid 1px;
      }
        
      .total td{
        font-weight: bold;
        font-size: 16px;
      }
      .trustDepositWrapper aside span{
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: column;
        line-height: 70px;
        border-bottom: #ddd solid 1px;
      }
      .trustDepositWrapper table{
          width: calc(100% - 100px);
          max-width: calc(100% - 100px);;

        }
    `}
  </style>
)
}
